





































































































































import { BSidebar, BForm, BFormGroup, BFormInput, BAvatar, BFormTextarea, BFormInvalidFeedback, BButton } from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import { ref, Ref } from "@vue/composition-api";
import { Component, Watch, Prop, Vue } from "vue-property-decorator";
import formValidation from "@core/comp-functions/forms/form-validation";
import useSegmentHandler from "./useSegmentHandler";
import { AvSkeletonInput } from "@/components/av-skeleton";
import AvButton from "@/components/av-button/AvButton.vue";
import { ISegmentUpdate } from "@/@core/services/interfaces/vehicle/segment/ISegmentService";
// Interfaces

@Component({
  name: "SegmentEditHandler",
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BAvatar,
    BFormTextarea,
    BFormInvalidFeedback,
    BButton,
    AvButton,
    vSelect,
    flatPickr,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    AvSkeletonInput,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isSegmentSideBarActive",
    event: "update:is-segment-handler-sidebar-active",
  },
})
export default class SegmentEditHandler extends Vue {
  @Prop({ required: true, default: false })
  isSegmentSideBarActive!: Ref<boolean>;
  @Prop({ required: true, default: [] }) statusOptions!: Array<{
    label: string;
    value: boolean;
  }>;
  @Prop({ required: true, default: [] }) typeOptions!: Array<{
    label: string;
    value: number;
  }>;
  @Prop({ required: true }) segmentId!: number;
  @Prop({ required: true, default: () => {} })
  clearSegmentData!: () => void;

  required = required;
  isSavingChanges: boolean = false;
  loading: Ref<boolean> = ref(false);
  segmentLocal: ISegmentUpdate = {} as ISegmentUpdate;

  resetSegmentLocal() {
    this.segmentLocal.id = this.segmentId;
  }

  clearForm() {
    this.segmentLocal = {} as ISegmentUpdate;
  }

  useSegmentHandler = useSegmentHandler(this);
  formValidation = formValidation(this.resetSegmentLocal, this.clearForm);

  // Computeds
  get isLoading(): boolean {
    return this.loading.value;
  }

  get isSidebarActive(): boolean {
    return this.isSegmentSideBarActive.value;
  }

  @Watch("segmentId", { immediate: true })
  reset() {
    if (typeof this.segmentId == "number") {
      this.clearForm();
    } else {
      this.clearForm();
    }
  }

  @Watch("isSidebarActive")
  handleSidebar(val: boolean) {
    this.clearForm();
    if (!val) {
      setTimeout(() => {}, 350);
    }

    if (this.isSidebarActive && this.segmentId) {
      this.loading.value = true;
      this.useSegmentHandler
        .fetchGetSegmentById(this.segmentId)
        .then((response) => {
          this.segmentLocal = {
            id: response.Id || -1,
            ativo: response.Ativo,
            codigo: response.Codigo,
            nome: response.Nome,
            tipo: response.Tipo,
          };
        })
        .finally(() => {
          this.loading.value = false;
        });
    }
  }

  async onSubmit() {
    this.loading.value = true;
    this.isSavingChanges = true;

    if (this.segmentLocal.id) {
      await this.useSegmentHandler.fetchUpdateSegment(this.segmentLocal);
    } else {
      await this.useSegmentHandler.fetchCreateSegment(this.segmentLocal);
    }

    this.$emit("updateIsSegmentSidebarActive", false);
    this.$emit("refreshData");
    this.clearForm();
    this.isSavingChanges = false;
    this.loading.value = false;
  }

  toggleSidebarSegmentHandler = (val: boolean): void => {
    this.$emit("updateIsSegmentSidebarActive", val);
  };

  reduceValueStatus = (val: { label: string; value: boolean }): boolean => {
    return val.value;
  };
}
